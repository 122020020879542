import { ChainId } from '@pancakeswap/chains'
import { Address } from 'viem'

export const MULTICALL_ADDRESS: { [key in ChainId]?: Address } = {
  [ChainId.ZKSYNC]: '0x95071cBD09184083E7F732a710c2e30c9882Fd5f',
  [ChainId.BSC]: '0x39eecaE833c944ebb94942Fa44CaE46e87a8Da17',
  [ChainId.ETHEREUM]: '0xC0916D7E360c31D5F6D0c497E6a36B7B0E80e3cf',
  [ChainId.ARBITRUM_ONE]: '0xbFfE39cDD04f0183e0493c1Deb6E275c5cf84AdF',
  [ChainId.POLYGON_ZKEVM]: '0xe05b539447B17630Cb087473F6b50E5c5f73FDeb',
  [ChainId.LINEA]: '0x6E6B30d65D605DAa4CaC65eB270100Ecca36b140',
  [ChainId.ZEUSCHAIN]: '0x49e134065d76d23Ed371A2eBDDD18847ca9aa8dD',
  [ChainId.PULSECHAIN]: '0x64382F665Aec8099a5eACF6fF9A91cE317f59E60',
  [ChainId.UNICHAIN_SEPOLIA]: '0x986a9Da85041490A02BC52335167E492ceA66e45',
  [ChainId.WORLDCHAIN]: '0x210F654f7a995fdBD6bFfc8bE5998C56df7CD3b7',
  [ChainId.APECHAIN]: '0x210F654f7a995fdBD6bFfc8bE5998C56df7CD3b7',
  [ChainId.BASECHAIN]: '0x3EFaAb8D7A631cfF5ccF5f149d1Bbb3B5bfda2C0',
  [ChainId.SONIC]: '0xA08EA3EF022c69f824469Fe69dE82Bc19A396e07',
  [ChainId.MORPHCHAIN]: '0x19F59F092e38044Fa0100893d9563A317d43A22F',
  [ChainId.INKCHAIN]: '0xC12CB48176eCb7C7077360Ea78EaE088ebc282AD',
  [ChainId.OPBNB]: '0xeF1511D29fB37cb87E33339EeC9BE13AB46E3b50',

  // Testnets
  [ChainId.BSC_TESTNET]: '0xeeF6ff30cF5D5b8aBA0DE16A01d17A0697a275b5',
  [ChainId.GOERLI]: '0xD55CAFAB2Ffa1139Be46bc5C0b8259c620050dFC',
  [ChainId.ARBITRUM_GOERLI]: '0xe12a5c707Fb528acbE6117b20AF1f7c20b0A8077',
  [ChainId.POLYGON_ZKEVM_TESTNET]: '0x236e713bFF45adb30e25D1c29A887aBCb0Ea7E21',
  [ChainId.ZKSYNC_TESTNET]: '0x8A23CB45E5F4d5a1b2DB673663Ea2aAedc48acff',
  [ChainId.LINEA_TESTNET]: '0x990010b6DBA3e7faa025790bC0433A9f690c65F3',
  [ChainId.BASE_TESTNET]: '0x6F7f93D929d6FBaF16c245e42846EF21aee23437',
  [ChainId.OPBNB_TESTNET]: '0x6A70ED893D85cf6D4059e1CF3e63a48e4D204D32',
  [ChainId.SCROLL_SEPOLIA]: '0x052a99849Ef2e13a5CB28275862991671D4b6fF5',
}

export const MULTICALL3_ADDRESS = '0xcA11bde05977b3631167028862bE2a173976CA11'

export const MULTICALL3_ADDRESSES: {
  [key in ChainId]?: Address
} = {
  [ChainId.ETHEREUM]: MULTICALL3_ADDRESS,
  [ChainId.GOERLI]: MULTICALL3_ADDRESS,
  [ChainId.BSC]: MULTICALL3_ADDRESS,
  [ChainId.BSC_TESTNET]: MULTICALL3_ADDRESS,
  [ChainId.ZKSYNC_TESTNET]: '0xF9cda624FBC7e059355ce98a31693d299FACd963',
  [ChainId.ZKSYNC]: '0xF9cda624FBC7e059355ce98a31693d299FACd963',
  [ChainId.ARBITRUM_ONE]: MULTICALL3_ADDRESS,
  [ChainId.ARBITRUM_GOERLI]: MULTICALL3_ADDRESS,
  [ChainId.POLYGON_ZKEVM]: MULTICALL3_ADDRESS,
  [ChainId.POLYGON_ZKEVM_TESTNET]: MULTICALL3_ADDRESS,
  [ChainId.OPBNB]: MULTICALL3_ADDRESS,
  [ChainId.OPBNB_TESTNET]: MULTICALL3_ADDRESS,
  [ChainId.BASE_TESTNET]: MULTICALL3_ADDRESS,
  [ChainId.SCROLL_SEPOLIA]: MULTICALL3_ADDRESS,
  [ChainId.ZEUSCHAIN]: '0x614f8698661bB8c933E022E8474dd220A5994161',
  [ChainId.PULSECHAIN]: MULTICALL3_ADDRESS,
  [ChainId.UNICHAIN_SEPOLIA]: MULTICALL3_ADDRESS,
  [ChainId.WORLDCHAIN]: MULTICALL3_ADDRESS,
  [ChainId.APECHAIN]: MULTICALL3_ADDRESS,
  [ChainId.BASECHAIN]: MULTICALL3_ADDRESS,
  [ChainId.SONIC]: MULTICALL3_ADDRESS,
  [ChainId.MORPHCHAIN]:'0x35f965903A85e7528437C3Ce0b4bdfbc4E5Fc27c',
  [ChainId.INKCHAIN]: MULTICALL3_ADDRESS,
  
}
